import React from "react";
import { Badge, Card, Col, Row } from "antd";
import styles from "./GameCard.module.scss";
import { NationalGame } from "../../types/types";
import { formatDate } from "../../utils/utils";

const GameCard = (props: NationalGame) => {
  return (
    <div>
      <Badge className={styles.Badge} count={props.status} />
      <Card className={styles.GameCard}>
        <p className={styles.GameDate}>{formatDate(props.gameDate)}</p>
        <p className={styles.GameCompetitionType}>{props.leagueType}</p>
        <Row
          justify="center"
          wrap={false}
          align="middle"
          gutter={16}
          style={{ marginTop: 32 }}
        >
          <Col>
            <div className={styles.TeamSection}>
              <img src={props.homeTeamLogo} alt="team-logo" />
              <p className={styles.TeamName}>{props.homeTeam}</p>
            </div>
          </Col>

          <Col className={styles.Result}>
            <p>
              {props.homeTeamScore}
              <span>-</span>
              {props.awayTeamScore}
            </p>
          </Col>

          <Col>
            <div className={styles.TeamSection}>
              <img src={props.awayTeamLogo} alt="team-logo" />
              <p className={styles.TeamName}>{props.awayTeam}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default GameCard;
