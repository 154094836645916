import { Carousel } from "antd";
import React from "react";
import Kosova from "../../assets/images/Kosova.jpg";
import Kosova2 from "../../assets/images/Kosova2.jpg";
import Kosova3 from "../../assets/images/Kosova3.jpg";
import styles from "./HeroSection.module.scss";

// const contentStyle: React.CSSProperties = {
//   color: "#fff",
//   lineHeight: "160px",
//   textAlign: "center",
//   background: "#364d79",
// };

const HeroSection = () => (
  <Carousel autoplay>
    <div className={styles.Container}>
      <img src={Kosova} alt={"news content"} />
      <div className={styles.MainNews}>
        <h1>
          Foda publikon listën e futbollistëve dardanë për dy ndeshjet e
          shtatorit
        </h1>
      </div>
    </div>
    <div className={styles.Container}>
      <img src={Kosova2} alt={"news content"} />
      <div className={styles.MainNews}>
        <h1>
          Foda publikon listën e futbollistëve dardanë për dy ndeshjet e
          shtatorit
        </h1>
      </div>
    </div>
    <div className={styles.Container}>
      <img src={Kosova3} alt={"news content"} />
      <div className={styles.MainNews}>
        <h1>
          Foda publikon listën e futbollistëve dardanë për dy ndeshjet e
          shtatorit
        </h1>
      </div>
    </div>
  </Carousel>
);

export default HeroSection;
