import React from "react";
import styles from "./Committee.module.scss";
import { CommitteeData } from "./committee-data";
import CommitteeMemberCard from "./committee-member-card/CommitteeMemberCard";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
const Committee = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>Komiteti Ekzekutiv i FFK-së</h1>
      </div>
      <div className={styles.ContentWrapper}>
        <p>
          <span>Federata e Futbollit e Kosovës</span> (FFK) drejtohet nga
          Komisioni Ekzekutiv, një organ udhëheqës që merret me vendimmarrjen
          strategjike dhe zhvillimin e futbollit në Kosovë. Ky komision
          përfaqëson interesat e klubeve dhe futbollistëve në nivel kombëtar.
        </p>
        <div className={styles.Committee}>
          {Object.keys(CommitteeData).map((key) => (
            <CommitteeMemberCard
              title={key}
              key={key}
              classname={classNames({
                [styles.SingleItem]: key === "presidenti",
              })}
            >
              <div
                key={key}
                className={classNames(styles.Item, {
                  [styles.SingleItem]: key === "presidenti",
                })}
              >
                {CommitteeData[key].map((member, index) => (
                  <div
                    className={styles.ItemContent}
                    key={index}
                    onClick={() => {
                      if (key === "presidenti") navigate("/presidenti");
                    }}
                  >
                    <img
                      src={member.image}
                      alt=""
                      className={styles.ItemImage}
                    />
                    <div className={styles.ItemDescription}>
                      <h2>{member.title}</h2>
                      <h4>{member.subTitle}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </CommitteeMemberCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Committee;
