import React from "react";
import { Button } from "antd";
import Play from "../../assets/images/Player.png";
import styles from "./VideoCard.module.scss";
import NewsPhotoTest from "../../assets/images/news-photo-test.png";
import { useTranslation } from "react-i18next";

const VideoCard = () => {
  const { t } = useTranslation();
  const videoLink = "https://www.youtube.com/watch?v=1PpM-RoUrC0"; // Replace with your video URL

  return (
      <a href={videoLink} target="_blank" rel="noopener noreferrer" className={styles.VideoCardLink}>
        <div className={styles.VideoCardSizes}>
          <div className={styles.ImageContainer}>
            <img alt="news title" src={NewsPhotoTest} className={styles.NewsImage} />
            <Button
                icon={<img src={Play} />}
                className={styles.PlayButton}
                shape="circle"
            />
          </div>
        </div>
      </a>
  );
};

export default VideoCard;
