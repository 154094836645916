import React from "react";
import styles from "./Committees.module.scss";
import CardRow from "../../components/_shared/card-row/CardRow";

const Committees_data = [
  {
    title:
      "Komisioni Organizativ i Garave dhe i Ligave të FFK-së, për Superligë dhe Ligën e Parë",
    email: "komisionipergara@ffk-kosova.com",
    members: [
      { name: "Abdyl Bellopoja", status: "Kryetar" },
      { name: "Isak Ejupi", status: "Anëtar" },
      { name: "Agim Kastrati", status: "Anëtar" },
    ],
  },
  {
    title:
      "N/Komisioni Organizativ i Garave dhe i Ligave të FFK-së, për Ligën e Dytë dhe Ligat e Juniorëve",
    email: "test@gmail.com",
    members: [
      { name: "Ardian Llumnica", status: "Kryetar" },
      { name: "Bardhec Seferi", status: "Anëtar" },
      { name: "Ekrem Buçinca", status: "Anëtar" },
    ],
  },
  {
    title: "Komisioni Disiplinor dhe i Etikes",
    email: "komisionidisiplinor@ffk-kosova.com",
    members: [
      { name: "Metë Çeku", status: "Kryetar" },
      { name: "Dukagjin Zefi", status: "Anëtar" },
      { name: "Luan Salihu", status: "Anëtar" },
      { name: "Avni Shala", status: "Anëtar" },
      { name: "Donat Lushaku", status: "Anëtar" },
    ],
  },
  {
    title: "Komisioni i Ankesave",
    email: "komisioniperankesa@ffk-kosova.com",
    members: [
      { name: "Haki Xhemajli", status: "Kryetar" },
      { name: "Sitki Baftiu", status: "Anëtar" },
      { name: "Remzi Azemi", status: "Anëtar" },
    ],
  },
  {
    title: "Komisioni i Referëve",
    email: "referimi@ffk-kosova.com",
    members: [{ name: "Përparim Hasani", status: "Kryetar" }],
  },
  {
    title:
      "N/Komisioni për delegimin e gjyqtarëve dhe kontrolluesëve për Superligë dhe Ligën e Parë",
    email: "test@gmail.com",
    members: [
      { name: "Gani Havolli", status: "Kryetar" },
      { name: "Arta Osmani", status: "Anëtare" },
      { name: "Zenel Qorraj", status: "Anëtar" },
      { name: "Kujtim Bajrami", status: "Anëtar" },
    ],
  },
  {
    title: "N/Komisioni për vlerësim",
    email: "test@gmail.com",
    members: [
      { name: "Besfort Kasumi", status: "Kryetar" },
      { name: "Ismet Shurdhani", status: "Anëtar" },
      { name: "Ergyn Janovali", status: "Anëtar" },
    ],
  },
  {
    title: "Komisioni i Infrastrukturës dhe Projekteve",
    email: "test@gmail.com",
    members: [
      { name: "Isak Rakovica", status: "Kryetar" },
      { name: "Haris Abdixhiku", status: "Anëtar" },
      { name: "Refki Osmani", status: "Anëtar" },
      { name: "Bedri Avdiu", status: "Anëtar" },
      { name: "Fetah Mehana", status: "Anëtar" },
    ],
  },
  {
    title:
      "Komisioni i Futsalit (Amatorëve, Fillestarëve (Grassroots), dhe Mini-Futboll)",
    email: "test@gmail.com",
    members: [
      { name: "Hysni Gjinovci", status: "Kryetar" },
      { name: "Agim Avdyli", status: "Anëtar" },
      { name: "Getart Hyseni", status: "Anëtar" },
    ],
  },
  {
    title: "Komisioni për statusin e lojtarëve",
    email: "test@gmail.com",
    members: [
      { name: "Bekim Behrami", status: "Kryetar" },
      { name: "Agim Bahtiri", status: "Anëtar" },
      { name: "Besarta Muhadri", status: "Anëtare" },
    ],
  },
  {
    title: "Komisioni i Financave",
    email: "test@gmail.com",
    members: [
      { name: "Fatmir Gashi", status: "Kryetar" },
      { name: "Ymer Mehmeti", status: "Anëtar" },
    ],
  },
];

const Committees = () => {
  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>KOMISIONET E FFK-SË</h1>
      </div>
      <div className={styles.ListWrapper}>
        {Committees_data.map((data, index) => (
          <div key={index} className={styles.CommitteeRow}>
            <CardRow
              data={{
                title: data.title,
                navigationLink: {
                  link: data?.email && `Email: ${data.email}`,
                  onLinkClick: () => undefined,
                },
              }}
              colors="BLUE"
              align="CENTER"
            />
            <div className={styles.MembersWrapper}>
              {data.members.map((member, memberIndex) => (
                <div key={memberIndex} className={styles.Member}>
                  <span className={styles.Name}>{member.name}</span>
                  <span className={styles.Status}>{member.status}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Committees;
