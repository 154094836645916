export function formatDate(dateStr: string) {
  const date = new Date(dateStr);

  return date
    .toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace("T", " -");
}

export function replaceStringSpaces(str: string) {
  return str.replace(/\s+/g, "-");
}
