import React from "react";
import styles from "./Normative.module.scss";
import CardRow from "../../components/_shared/card-row/CardRow";

const NormativeActs_data = [
  {
    title: "Statuti – akti Themelor",
    navigationLink: {
      title: "lexo dokument",
      link: "https://www.ffk-kosova.com/wp-content/uploads/2023/02/230127-Statuti-i-FFK-se-alb-2023.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Superligës dhe Kupës edicioni 2024/2025",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/Rregullorja-e-Garave-te-Superliges-dhe-Kupes-Edicioni-2024-2025.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Ligës së Parë dhe Kupës edicioni 2024/2025",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/Rregullorja-e-Garave-LIGA-E-PARE-dhe-Kupes-Edicioni-2024-2025.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Ligës së Dytë dhe Kupës edicioni-2024/2025",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/Rregullorja-e-Garave-LIGA-E-DYTE-dhe-Kupes-Edicioni-2024-2025.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Superligës U21 edicioni 2024/2025",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/Rregullorja-e-Garave-LIGA-E-DYTE-dhe-Kupes-Edicioni-2024-2025.pdf",
    },
  },
  {
    title:
      "Rregullorja e Garave për ligat U-9, U-11, U-13, U-15, U-17 dhe U-19 edicioni 2024/2025",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/Rregullorja-e-Garave-te-U-9-U-11-U-13-U-15-U-17-U-19-Edicioni-2024-2025-FFK.pdf",
    },
  },
  {
    title: "Kodi i Disiplinës dhe Etikës 2024",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/10/240808-Kodi-i-Disiplines-dhe-tikes-2024_finale-3.pdf",
    },
  },
  {
    title: "Protokolli i VAR-it",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2022/10/3.-PROTOKOLI-I-VAR-it-2.pdf",
    },
  },
  {
    title: "Propozicionet e garave për ligat e vajzave",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/04/Propozicionet-e-garave-per-vajza-gra-2023-2024.pdf",
    },
  },
  {
    title: "Propozicionet e Garave për futsall 2024/25",
    navigationLink: {
      link: "",
    },
  },
  {
    title: "Rregullorja për statusin e lojtarëve",
    navigationLink: {
      link: "",
    },
  },
  {
    title: "Rregullorja e UEFA-s për infrastrukturën e stadiumeve",
    navigationLink: {
      link: "",
    },
  },
  {
    title: "Politika mbi mbrojtjen dhe sigurinë e fëmijëve në futboll",
    navigationLink: {
      link: "https://www.ffk-kosova.com/wp-content/uploads/2024/04/POLITIKA-MBI-MBROJTJEN-DHE-SIGURINA_-E-FA_MIJA_VE-NA_-FUTBOLL.pdf",
    },
  },
];

const NormativeActs = () => {
  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>Aktet Normative</h1>
      </div>
      <div className={styles.ListWrapper}>
        {NormativeActs_data.map((data, index) => (
          <CardRow
            key={index}
            data={{
              ...data,
              id: index + 1,
              navigationLink: {
                ...data.navigationLink,
                link: "Lexo Dokumentin",
                onLinkClick: () =>
                  window.open(data.navigationLink?.link, "_blank"),
              },
            }}
            colors="INVERTED"
          />
        ))}
      </div>
    </div>
  );
};

export default NormativeActs;
