import React from "react";
import { Badge, Card, Col, Row } from "antd";
import styles from "./CompetitionGameCard.module.scss";
import teamLogo from "../../assets/images/ffk-logo.png";

const CompetitionGameCard = () => {
  return (
      <div>
        <Badge className={styles.Badge} count="LIVE" />
        <Card className={styles.CompetitionGameCardContainer}>
          <p className={styles.GameDate}>19 May 2024 - 3:00 pm</p>
          <p className={styles.GameCompetitionType}>Raiffeisen Liga e Parë | Java 27</p>
          <Row
              justify="center"
              align="middle"
              gutter={16}
              style={{ marginTop: 16 }}
          >
            <Col>
              <div className={styles.TeamSection}>
                <img src={teamLogo} alt="team-logo" />
                <p className={styles.TeamName}>SUHAREKA</p>
              </div>
            </Col>

            <Col className={styles.Result}>
              <p>2<span>-</span>1</p>
            </Col>

            <Col>
              <div className={styles.TeamSection}>
                <img src={teamLogo} alt="team-logo" />
                <p className={styles.TeamName}>SUHAREKA</p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
  );
};

export default CompetitionGameCard;
