import React from "react";
import { PropsWithChildren } from "react";
import styles from "./CommitteeMemberCard.module.scss";
import classNames from "classnames";

interface CommitteeMemberCard extends PropsWithChildren {
  title: string;
  classname?: string;
}

const CommitteeMemberCard = ({
  title,
  classname,
  children,
}: CommitteeMemberCard) => {
  return (
    <>
      <p className={classNames(styles.ItemTitle, classname)}>{title}</p>
      {children}
    </>
  );
};

export default CommitteeMemberCard;
