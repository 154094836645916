import React from "react";
import styles from "./Intermediaries.module.scss";
import CardRow from "../../components/_shared/card-row/CardRow";

const Intermediaries_data = [
  { title: "Ilir Morina", other: "01/2018 (16.07.2018)" },
  { title: "Suad Ramadani", other: "02/2018 (16.07.2018)" },
  { title: "Ergin Kurda", other: "03/2018 (16.07.2018)" },
  { title: "Xhavit Abazi", other: "04/2018 (16.07.2018)" },
  { title: "Jusuf Tortoshi", other: "05/2018 (16.08.2018)" },
  { title: "Egzon Burri", other: "06/2018 (16.08.2018)" },
  { title: "Ismet Mehmeti", other: "07/2018 (24.08.2018)" },
  { title: "Fat Gjakova", other: "08/2018 (24.08.2018)" },
  { title: "Hashim Vitia", other: "09/2018 (12.09.2018)" },
  { title: "Adem Dilji", other: "10/2018 (12.09.2018)" },
  { title: "Besnik Gojani", other: "11/2018 (29.10.2018)" },
  { title: "Fisnik Salihu", other: "12/2018 (29.10.2018)" },
  { title: "Ekrem Latifi", other: "13/2019 (25.01.2019)" },
  { title: "Shaqir Kutllovci", other: "14/2019 (25.01.2019)" },
  { title: "Blerim Martinaj", other: "15/2019 (31.01.2019)" },
  { title: "Sadik Bytyqi", other: "16/2019 (31.01.2019)" },
  { title: "Urim Sejdiu", other: "17/2019 (07.02.2019)" },
];

const Intermediaries = () => {
  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>Ndërmjetësuesit</h1>
        <h4>Lista e personave që janë pajisur me Certifikatë Ndërmjetësimi</h4>
      </div>
      <div className={styles.ListWrapper}>
        {Intermediaries_data.map((data, index) => (
          <CardRow data={{ ...data, id: index + 1 }} />
        ))}
      </div>
    </div>
  );
};

export default Intermediaries;
