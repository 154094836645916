import React from "react";
import styles from "./Footer.module.scss";
import ffkLogo from "../../assets/images/ffk-logo-sm.png";
import {Col, Row} from "antd";
import facebook from "../../assets/images/facebook-icon.png";
import instagram from "../../assets/images/instagram-icon.png";
import youtube from "../../assets/images/youtube-icon.png";
import twitter from "../../assets/images/twitter-icon.png";
import {UpOutlined} from "@ant-design/icons";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };


  return (
      <div className={styles.Footer}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <img src={ffkLogo} alt="FFK Logo"/>
        </div>
        <div className={styles.FooterContact}>
          <Row>
            <Col xs={24} sm={8} className={styles.Col}>
              <h3>Contact Number:</h3>
              <p>+383 38 600 220</p>
            </Col>
            <Col xs={24} sm={8} className={styles.Col}>
              <h3>Email Address:</h3>
              <p>Marketing@Ffk-Kosova.Com</p>
            </Col>
            <Col xs={24} sm={8} className={styles.Col}>
              <h3>Location:</h3>
              <p>Rruga “28 Nëntori”, Nr. 171</p>
            </Col>
          </Row>
        </div>
        <div className={styles.CopyRight}>
          <p>
            © 2024 Football Federation of Kosovo. are registered trademarks of
            Football Federation of Kosovo. The team names, logos and uniform
            designs are registered trademarks of the teams indicated. All other
            FFK-related trademarks are trademarks of Football Federation of Kosovo
          </p>
          <div>
            <button className={styles.ScrollToTop} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
              <UpOutlined/>
            </button>
          </div>
        </div>
        <div className={styles.SocialMedia}>
          <div className={styles.SocialMediaLinks}>
            <a>KONTAKTI</a>
            <a>PRIVACY POLICY</a>
            <a>TEARMS OF USE</a>
          </div>
          <div className={styles.Federata}>
            <p>Federata e Futbollit e Kosovës</p>
          </div>
          <div className={styles.SocialMediaIcons}>
            <a href="https://www.facebook.com/FFKKosova" target="_blank" rel="noopener noreferrer">
              <img src={facebook}/>
            </a>
            <a href="https://www.instagram.com/FFK_KOS/?fbclid=IwY2xjawGrD9JleHRuA2FlbQIxMAABHT-HThdI7dDvTfuofH1zxpB40lxhyV7kX-BvOeW_u7TTZq15avcLD9ma-w_aem_bvwGRx3JmF5mRiIkI0Segw#"
               target="_blank" rel="noopener noreferrer">
              <img src={instagram}/>
            </a>
            <a href="https://www.youtube.com/c/FederataeFutbolliteKosov%C3%ABs" target="_blank"
               rel="noopener noreferrer">
              <img src={youtube}/>
            </a>
            <a href="https://twitter.com/FFK_KS" target="_blank" rel="noopener noreferrer">
              <img src={twitter}/>
            </a>
          </div>
        </div>
      </div>
  );
};

export default Footer;
