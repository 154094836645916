import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/utils";
import { News } from "../../types/types";
import { Carousel } from "antd";
import styles from "./NewsDetails.module.scss";
import { CarouselRef } from "antd/es/carousel";
const NewsDetails = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const state = location.state as News;
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <div className={styles.MainWrapper}>
      {state ? (
        <div className={styles.ContentWrapper}>
          <p>{state.publishedDate && formatDate(state.publishedDate)}</p>
          <h1>{i18n.language === "sq" ? state.alTitle : state.enTitle}</h1>
          <img src={state.mainImageUrl} alt="news main image" />
          <p>
            {i18n.language === "sq" ? state.alDescription : state.enDescription}
          </p>
          {state.additionalImageUrls &&
            state.additionalImageUrls.length > 0 && (
              <div className={styles.AdditionalImagesWrapper}>
                <Carousel
                  ref={carouselRef}
                  autoplay
                  dots={false}
                  infinite
                  className={styles.AdditionalImages}
                >
                  {state.additionalImageUrls.map((imageUrl) => (
                    <img src={imageUrl} alt="aditional image" />
                  ))}
                </Carousel>
                <div className={styles.ArrowWrapper}>
                  <button
                    className={styles.Arrow}
                    onClick={() => {
                      if (carouselRef.current) {
                        carouselRef.current.prev();
                      }
                    }}
                  >
                    ‹
                  </button>
                  <button
                    className={styles.Arrow}
                    onClick={() => {
                      if (carouselRef.current) {
                        carouselRef.current.next();
                      }
                    }}
                  >
                    ›
                  </button>
                </div>
              </div>
            )}
        </div>
      ) : (
        <h1>Data not found</h1>
      )}
    </div>
  );
};

export default NewsDetails;
