import React from 'react';
import styles from './Partners.module.scss';
import Teb from "../../assets/images/teb-logo.png";
import ArtMotion from "../../assets/images/artmotion-logo.png";
import Errea from "../../assets/images/errea-logo.png";
import Raiffaisen from "../../assets/images/raiffaisen-logo.png";
import Sigal from "../../assets/images/sigal-logo.png";
import Mokne from "../../assets/images/mokne-logo.png";
import Emerald from "../../assets/images/emerald-logo.png";
import AlTrade from "../../assets/images/altrade-logo.png";
import AlbiMall from "../../assets/images/albimall-logo.png";
import KepBank from "../../assets/images/kep-logo.png";

const Partners = () => {
  const partnerLogos = [
    { src: ArtMotion, alt: "Art Motion", url: "https://artmotion.net/" },
    { src: AlbiMall, alt: "Albi Mall", url: "https://www.facebook.com/albimall/" },
    { src: AlTrade, alt: "Al Trade", url: "https://altradecenter.com/" },
    { src: Mokne, alt: "Mokne", url: "https://mokne.com/" },
    { src: Emerald, alt: "Emerald", url: "https://emeraldhotel.info/" },
    { src: Errea, alt: "Errea", url: "https://www.errea.com/" },
    { src: Sigal, alt: "Sigal", url: "https://www.facebook.com/SigalKosova" },
    { src: Raiffaisen, alt: "Raiffaisen Bank", url: "https://www.raiffeisen-kosovo.com/sq/individe.html" },
    { src: Teb, alt: "Teb", url: "https://teb-kos.com/" },
    { src: KepBank, alt: "Kep", url: "https://keptrust.org/" },
  ];

  return (
      <div className={styles.PartnersContainer}>
        <div className={styles.Marquee}>
          <div className={styles.MarqueeContent}>
            {[...partnerLogos, ...partnerLogos, ...partnerLogos].map((logo, index) => (
                <img
                    key={index}
                    src={logo.src}
                    alt={logo.alt}
                    onClick={() => window.open(logo.url, '_blank')}
                    style={{ cursor: 'pointer' }}
                />
            ))}
          </div>
        </div>
      </div>
  );
};

export default Partners;
