import React, { useRef } from "react";
import { Carousel as AntCarousel, Carousel } from "antd"; // Import CarouselRef directly
import styles from "./Gallery.module.scss";
import GalleryBackground from "../../assets/images/kosovo.jpeg";
import Suhareka from "../../assets/images/suhareka.jpeg";
import Kryetari from "../../assets/images/kryetari.jpeg";
import Drita from "../../assets/images/drita.jpeg";
import Vojvoda from "../../assets/images/vojvoda.jpeg";
import {CarouselRef} from "antd/es/carousel";

const Gallery = () => {
  const carouselRef = useRef<CarouselRef>(null);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
      <div className={styles.GalleryContainer}>
        <img src={GalleryBackground} alt="Gallery Background" />
        <div className={styles.GalleryContent}>
          <h1>FFK <span>MOMENTS</span></h1>
          <AntCarousel
              autoplay
              className={styles.Carousel}
              dots={false}
              ref={carouselRef}
          >
            <div className={styles.Slide}>
              <div className={styles.ImageGroup}>
                <img src={Suhareka} alt="Suhareka" />
                <img src={Kryetari} alt="Kryetari" />
                <img src={Drita} alt="Drita" />
                <img src={Vojvoda} alt="Vojvoda" />
              </div>
            </div>
            <div className={styles.Slide}>
              <div className={styles.ImageGroup}>
                <img src={Vojvoda} alt="Vojvoda" />
                <img src={Suhareka} alt="Suhareka" />
                <img src={Kryetari} alt="Kryetari" />
                <img src={Drita} alt="Drita" />
              </div>
            </div>
          </AntCarousel>
          <div className={styles.ArrowContainer}>
            <button className={styles.PrevArrow} onClick={handlePrev}>
              ‹
            </button>
            <button className={styles.NextArrow} onClick={handleNext}>
              ›
            </button>
          </div>
        </div>
      </div>
  );
};

export default Gallery;
