import React, { useMemo } from "react";
import styles from "./Competition.module.scss";
import { useLocation, useParams } from "react-router-dom";
import AlbiMallSuperligaImage from "../../assets/images/albi-mall-superliga.png";
import TeamLogo from "../../assets/images/team-logo.png";
import { Avatar, Badge, Table } from "antd";
import { UpOutlined } from "@ant-design/icons"; // Sample data for clubs

// Sample data for clubs
const data = [
  {
    key: 1,
    club: "Ballkani",
    type: "champion",
    MP: 0,
    W: 0,
    D: 0,
    L: 0,
    GF: 0,
    GA: 0,
    GD: 0,
    Pts: 0,
  },
  {
    key: 2,
    club: "Prishtina",
    type: "champion",
    MP: 0,
    W: 0,
    D: 0,
    L: 0,
    GF: 0,
    GA: 0,
    GD: 0,
    Pts: 0,
  },
  {
    key: 3,
    club: "Team Name",
    type: "promotion",
    MP: 0,
    W: 0,
    D: 0,
    L: 0,
    GF: 0,
    GA: 0,
    GD: 0,
    Pts: 0,
  },
  // Additional rows...
];

// Column definitions
const columns = [
  {
    title: "CLUB",
    dataIndex: "club",
    key: "club",
    width: "80%",
    render: (text: any, record: any) => (
      <div className={styles.ClubsColumn}>
        <Avatar
          shape="square"
          size="small"
          icon={<img src={TeamLogo} alt="club" />}
        />
        <span style={{ marginLeft: 8 }}>{text}</span>
      </div>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type: any) => {
      let color;
      let icon;

      if (type === "champion") {
        color = "blue";
        icon = <UpOutlined />;
      } else if (type === "promotion") {
        color = "orange";
        icon = <UpOutlined />;
      } else if (type === "relegation") {
        color = "green";
        icon = <UpOutlined />;
      }

      return <Badge color={color} text={icon} />;
    },
  },
  { title: "MP", dataIndex: "MP", key: "MP" },
  { title: "W", dataIndex: "W", key: "W" },
  { title: "D", dataIndex: "D", key: "D" },
  { title: "L", dataIndex: "L", key: "L" },
  { title: "GF", dataIndex: "GF", key: "GF" },
  { title: "GA", dataIndex: "GA", key: "GA" },
  { title: "GD", dataIndex: "GD", key: "GD" },
  {
    title: "Pts",
    dataIndex: "Pts",
    key: "Pts",
    render: (pts: any) => <span style={{ color: "green" }}>{pts}</span>,
  },
];

const Competition = () => {
  const { title } = useParams();

  //TODO: use categoryId from state to get the match table
  const { state } = useLocation();

  const formattedTitle = useMemo(() => {
    return title?.replace(/-/g, " ").toUpperCase();
  }, [title]);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>{formattedTitle}</h1>
      </div>
      <div className={styles.ContentWrapper}>
        <img src={AlbiMallSuperligaImage} alt="leage-image" />
        <div className={styles.TableWrapper}>
          <div className={styles.TableTitle}>
            <h2>{formattedTitle}</h2>
            <p>Tabela</p>
          </div>
          <div className={styles.TableContent}>
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competition;
