import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import NavigationBar from "./components/navigation-bar/NavigationBar";
import Footer from "./components/footer/Footer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavigationBar />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
);
