import React, { useMemo } from "react";
import { Card } from "antd";
import styles from "./NewsCard.module.scss";
import { useTranslation } from "react-i18next";
import { News } from "../../../types/types";
import { formatDate, replaceStringSpaces } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const NewsCard: React.FC<News> = (props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const navigationTitle = useMemo(() => {
    return i18n.language === "sq"
      ? replaceStringSpaces(props.alTitle)
      : replaceStringSpaces(props.enTitle);
  }, [i18n, props, replaceStringSpaces]);

  return (
    <Card
      className={styles.CardSizes}
      hoverable
      onClick={() => navigate(`/lajmet/${navigationTitle}`, { state: props })}
      cover={
        <div className={styles.ImageContainer}>
          <img
            alt="News title"
            src={props.mainImageUrl}
            className={styles.NewsImage}
          />
          <div className={styles.Badge}>{props.subcategory.name}</div>
        </div>
      }
    >
      <div className={styles.CardContent}>
        <h3>{i18n.language === "sq" ? props.alTitle : props.enTitle}</h3>
        <p>{formatDate(props.publishedDate)}</p>
      </div>
    </Card>
  );
};

export default NewsCard;
