import Kryetari from "./../../assets/images/kryetari.jpeg";

type CommitteeMember = {
  title: string;
  subTitle: string;
  image: string;
};

export type CommitteeDataType = {
  [key: string]: CommitteeMember[];
};

export const CommitteeData: CommitteeDataType = {
  presidenti: [
    { title: "Agim Ademi", subTitle: "Presidenti", image: Kryetari },
  ],
  nenkryetaret: [
    {
      title: "Bakir Burri",
      subTitle: "Nenkryetar",
      image: Kryetari,
    },
    {
      title: "Agim Dibrani",
      subTitle: "Nenkryetar",
      image: Kryetari,
    },
    {
      title: "Predrag Joviq",
      subTitle: "Nenkryetar",
      image: Kryetari,
    },
  ],
  anetaret: [
    {
      title: "Vjollca Krasniqi",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Xhelal Broja",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Ilaz Mjaku",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Mirsad Collaku",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Ismajl Ajeti",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Naser Ajeti",
      subTitle: "anetar",
      image: Kryetari,
    },
    {
      title: "Agim Nura",
      subTitle: "anetar",
      image: Kryetari,
    },
  ],
};
