import React, { useState } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { FormValues } from '../../types/types';
import usePost from '../../hooks/usePost';
import styles from "./VolunteerForm.module.scss";

const { Option } = Select;

const VolunteerForm: React.FC = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    city: '',
    gender: '',
    idNumber: '',
    email: '',
    age: '',
  });

  const { post, loading, error  } = usePost('/api/volunteers');  // Using usePost with a specific URL

  const validateName = (name: string) => {
    const nameRegex = /^[A-Za-z]+ [A-Za-z]+$/;
    return !name.trim() ? t("app.validations.required", "Required") :
        !nameRegex.test(name) ? t("app.volunteers.validation.nameFormat", "Duhet të jetë vetëm me shkronja dhe të përmbajë dy fjalë, p.sh. Filan Fisteku") : '';
  };

  const validateCity = (city: string) => {
    const cityRegex = /^[A-Za-z\s]+$/;
    return !city.trim() ? t("app.validations.required", "Required") :
        !cityRegex.test(city) ? t("app.volunteers.validation.cityFormat", "Qyteti duhet të përmbajë vetëm shkronja") : '';
  };

  const validateGender = (gender: string) => {
    return !gender.trim() ? t("app.validations.required", "Required") : '';
  };

  const validateIdNumber = (id: string) => {
    const idRegex = /^\d{10}$/;
    return !id.trim() ? t("app.validations.required", "Required") :
        !idRegex.test(id) ? t("app.volunteers.validation.idFormat", "Numri personal duhet të përmbajë 10 numra") : '';
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return !email.trim() ? t("app.validations.required", "Required") :
        !emailRegex.test(email) ? t("app.volunteers.validation.emailFormat", "Formati i pavlefshëm i email-it.") : '';
  };

  const validateAge = (age: string) => {
    const ageNumber = parseInt(age);
    return !age.trim() ? t("app.validations.required", "Required") :
        isNaN(ageNumber) || ageNumber < 18 ? t("app.volunteers.validation.ageRestriction", "Mosha duhet të jetë mbi 18 vjeç") : '';
  };

  const onFinish = async (values: FormValues) => {
    try {
      const response = await post(values);  // Call the post function from usePost
      console.log('Form data submitted:', response);
    } catch (err) {
      console.error('Form submission error:', error);
    }
  };

  return (
      <div className={styles.VolunteerFormContainer}>
        <Form onFinish={onFinish} initialValues={formValues}>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.name&surname", "Emri & Mbiemri")}</p>
              <Form.Item
                  name="name"
                  validateStatus={validateName(formValues.name) ? "error" : ""}
                  help={validateName(formValues.name)}
              >
                <Input
                    value={formValues.name}
                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.city", "Qyteti")}</p>
              <Form.Item
                  name="city"
                  validateStatus={validateCity(formValues.city) ? "error" : ""}
                  help={validateCity(formValues.city)}
              >
                <Input
                    value={formValues.city}
                    onChange={(e) => setFormValues({ ...formValues, city: e.target.value })}
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.gender", "Gjinia")}</p>
              <Form.Item
                  name="gender"
                  validateStatus={validateGender(formValues.gender) ? "error" : ""}
                  help={validateGender(formValues.gender)}
              >
                <Select
                    value={formValues.gender}
                    onChange={(value) => setFormValues({ ...formValues, gender: value })}
                >
                  <Option value="femer">Femër</Option>
                  <Option value="mashkull">Mashkull</Option>
                  <Option value="tjeter">Tjetër</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.idNumber", "Numri personal")}</p>
              <Form.Item
                  name="idNumber"
                  validateStatus={validateIdNumber(formValues.idNumber) ? "error" : ""}
                  help={validateIdNumber(formValues.idNumber)}
              >
                <Input
                    value={formValues.idNumber}
                    onChange={(e) => setFormValues({ ...formValues, idNumber: e.target.value })}
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.email", "Email")}</p>
              <Form.Item
                  name="email"
                  validateStatus={validateEmail(formValues.email) ? "error" : ""}
                  help={validateEmail(formValues.email)}
              >
                <Input
                    value={formValues.email}
                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.age", "Age")}</p>
              <Form.Item
                  name="age"
                  validateStatus={validateAge(formValues.age) ? "error" : ""}
                  help={validateAge(formValues.age)}
              >
                <Input
                    value={formValues.age}
                    onChange={(e) => setFormValues({ ...formValues, age: e.target.value })}
                />
              </Form.Item>
            </div>
          </div>

          {/*{error && <p style={{ color: 'red' }}>{error}</p>}*/}
        </Form>
      </div>
  );
};

export default VolunteerForm;
