import { useEffect, useState } from "react";

const useFetch = <T>(route: string) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${route}`,
        );
        if (!response.ok) {
          setError(new Error(`Error: ${response.status}`));
        }
        const result = await response.json();
        setData(result as T);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [route]);

  return { data, loading, error };
};

export default useFetch;
